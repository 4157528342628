/**

*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography, Box, Button, IconButton } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import PageLayout from "examples/LayoutContainers/PageLayout";
import brand from "assets/images/authpage_logo.png";
import landing_bottom from "assets/images/landing_bottom.png";
import landing_m1_l1 from "assets/images/landing_m1_l1.png";
import landing_m1_l2 from "assets/images/landing_m1_l2.png";
import landing_m1_r from "assets/images/landing_m1_r.png";
import landing_m2_l1 from "assets/images/landing_m2_l1.png";
import landing_m2_l2 from "assets/images/landing_m2_l2.png";
import landing_m2_r from "assets/images/landing_m2_r.png";
import landing_m3 from "assets/images/landing_m3.png";
import landing_m4_l from "assets/images/landing_m4_l.png";
import landing_m4_r from "assets/images/landing_m4_r.png";
import landing_top_left from "assets/images/landing_top_left.png";
import landing_top_right from "assets/images/landing_top_right.png";
import landing_trynow from "assets/images/landing_trynow.png";
import { useNavigate } from "react-router-dom";
import LandingDialog from "examples/LandingDialog";
import landing_m3logo1 from "assets/images/landing_m3logo1.png";
import landing_m3logo2 from "assets/images/landing_m3logo2.png";
import landing_m3logo3 from "assets/images/landing_m3logo3.png";
import landing_m3logo4 from "assets/images/landing_m3logo4.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from "react-copy-to-clipboard";

function LandingPage() {
  const navigate = useNavigate();
  const handleTry = () => {
    navigate("/dashboard");
  };

  const [infoDialog, setInfoDialog] = useState(false);
  const handleOpenInfoDialog = () => {
    setInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setInfoDialog(false);
  };

  const code = [
    `
  import OpenAI from "openai";

  const openai = new OpenAI({
    baseURL: "https://oai.helicone.ai/openai/deployments/[DEPLOYMENT_NAME]",
    defaultHeaders: {
      "Helicone-Auth": "Bearer <HELICONE_API_KEY>",
      "Helicone-OpenAI-API-Base": "https://[AZURE_DOMAIN].openai.azure.com",
      "api-key": "[AZURE_API_KEY]",
    },
    defaultQuery: {
      "api-version": "[API_VERSION]"
    },
  });
  `,
    `
  import openai

  openai.api_base = "https://oai.helicone.ai/openai/deployments/[DEPLOYMENT_NAME]"
  openai.api_key = "[AZURE_API_KEY]"

  headers = {
      "Helicone-Auth": "Bearer <HELICONE_API_KEY>",
      "Helicone-OpenAI-API-Base": "https://[AZURE_DOMAIN].openai.azure.com",
  }

  response = openai.ChatCompletion.create(
      model="[MODEL_NAME]",
      messages=[
          {"role": "user", "content": "Hello, world!"},
      ],
      headers=headers
  )
  print(response)
  `,
    `
  import { OpenAI } from "langchain/llms/openai";

  const openai = new OpenAI({
    baseURL: "https://oai.helicone.ai/openai/deployments/[DEPLOYMENT_NAME]",
    headers: {
      "Helicone-Auth": "Bearer <HELICONE_API_KEY>",
      "Helicone-OpenAI-API-Base": "https://[AZURE_DOMAIN].openai.azure.com",
      "api-key": "[AZURE_API_KEY]",
    },
    apiVersion: "[API_VERSION]",
  });

  const response = await openai.call("Hello, world!");
  console.log(response);
  `,
    `
  import { OpenAIChat } from "langchain/chat_models/openai";

  const chatModel = new OpenAIChat({
    baseURL: "https://oai.helicone.ai/openai/deployments/[DEPLOYMENT_NAME]",
    headers: {
      "Helicone-Auth": "Bearer <HELICONE_API_KEY>",
      "Helicone-OpenAI-API-Base": "https://[AZURE_DOMAIN].openai.azure.com",
      "api-key": "[AZURE_API_KEY]",
    },
    apiVersion: "[API_VERSION]",
  });

  const response = await chatModel.call([
    { role: "user", content: "Hello, world!" },
  ]);
  console.log(response);
  `,
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedButton, setSelectedButton] = useState(0);

  const tabs = ["javascript", "python", "javascript", "javascript"];

  const logoImages = [landing_m3logo1, landing_m3logo2, landing_m3logo3, landing_m3logo4];

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF)",
        }}
      >
        <Box height="8%" bgcolor="#ffffff" borderBottom={1} borderColor="#7F6CE0">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            px={3}
          >
            <Box display="flex" alignItems="center">
              <Box component="img" src={brand} alt="Brand" width="285px" height="70px" />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto", pr: 4 }}>
              <Button
                onClick={handleOpenInfoDialog}
                variant="contained"
                sx={{
                  color: "#737373",
                  backgroundColor: "#F5F5F5",
                  "&:hover": { backgroundColor: "#7F6CE0" },
                }}
              >
                联系我们
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="80vh"
            width="85%"
            mx="auto"
            flexWrap="wrap"
            sx={{
              overflow: "hidden",
            }}
          >
            <Box flex="1" textAlign="left">
              <Box
                component="img"
                src={landing_top_left}
                alt="Left"
                sx={{
                  width: "100%",
                  maxWidth: "690px",
                  height: "auto",
                }}
              />
              <Box
                component="img"
                src={landing_trynow}
                alt="Try Now Button"
                sx={{
                  mt: 7,
                  ml: 1,
                  display: "flex",
                  cursor: "pointer",
                  width: "200px",
                  height: "auto",
                }}
                onClick={handleTry}
              />
            </Box>
            <Box flex="1" textAlign="center">
              <Box
                component="img"
                src={landing_top_right}
                alt="Right"
                sx={{
                  width: "100%",
                  maxWidth: "906px",
                  height: "auto",
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
              sx={{
                overflow: "hidden",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={4}
                flex="1"
                maxWidth="542px"
                textAlign="left"
              >
                <Box
                  component="img"
                  src={landing_m1_l1}
                  alt="Top Left"
                  sx={{
                    width: "100%",
                    maxWidth: "542px",
                    height: "auto",
                  }}
                />
                <Box
                  component="img"
                  src={landing_m1_l2}
                  alt="Bottom Left"
                  sx={{
                    width: "100%",
                    maxWidth: "313px",
                    height: "auto",
                  }}
                />
              </Box>
              <Box flex="1" textAlign="right">
                <Box
                  component="img"
                  src={landing_m1_r}
                  alt="Right"
                  sx={{
                    width: "100%",
                    maxWidth: "860px",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
              sx={{
                overflow: "hidden",
              }}
            >
              <Box
                flex="1"
                maxWidth="860px"
                textAlign="center"
                position="relative"
                sx={{
                  height: "0",
                  paddingTop: "50%",
                }}
              >
                <Box
                  component="img"
                  src={landing_m2_l1}
                  alt="Stacked Background"
                  sx={{
                    position: "absolute",
                    top: "20%",
                    left: 0,
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
                <Box
                  component="img"
                  src={landing_m2_l2}
                  alt="Stacked Foreground"
                  sx={{
                    position: "absolute",
                    top: "30%",
                    left: "10%",
                    width: "100%",
                    height: "auto",
                    zIndex: 2,
                  }}
                />
              </Box>
              <Box
                flex="1"
                maxWidth="447px"
                textAlign="right"
                sx={{
                  height: "auto",
                  zIndex: 3,
                }}
              >
                <Box
                  component="img"
                  src={landing_m2_r}
                  alt="Right"
                  sx={{
                    width: "100%",
                    maxWidth: "447px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="80%"
                  px={4}
                >
                  <Box
                    component="img"
                    src={landing_m3}
                    alt="Top Left Image"
                    sx={{
                      width: "704px",
                      height: "135px",
                      objectFit: "cover",
                    }}
                  />
                  <Box display="flex" gap={5}>
                    {logoImages.map((img, index) => (
                      <Box
                        key={index}
                        component="img"
                        src={img}
                        alt={`Logo ${index + 1}`}
                        sx={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "25%",
                          border: selectedButton === index ? "10px solid #DBD6F5" : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedButton(index)}
                      />
                    ))}
                  </Box>
                </Box>
                <Box
                  width="100%"
                  maxWidth="80%"
                  mt={4}
                  bgcolor="#051538"
                  borderRadius={2}
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={2}>
                      {["node.js", "python", "langchain", "langchainJS"].map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            cursor: "pointer",
                            bgcolor:
                              selectedTab === index ? "rgba(255, 255, 255, 0.3)" : "transparent",
                            color: "#EDEAFA",
                            borderRadius: 2,
                            mx: 2,
                            px: 2,
                            my: 1,
                            py: 1,
                          }}
                          onClick={() => setSelectedTab(index)}
                        >
                          {item}
                        </Box>
                      ))}
                    </Box>
                    <CopyToClipboard text={code[selectedTab]}>
                      <IconButton
                        sx={{
                          color: "#ffffff",
                          "&:hover": {
                            color: "#c7c7c7",
                          },
                          mr: 2,
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                  <SyntaxHighlighter
                    language={tabs[selectedTab]}
                    style={coldarkDark}
                    showLineNumbers
                  >
                    {code[selectedTab]}
                  </SyntaxHighlighter>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
              sx={{
                overflow: "hidden",
              }}
            >
              <Box flex="1" textAlign="left">
                <Box
                  component="img"
                  src={landing_m4_l}
                  alt="Left"
                  sx={{
                    width: "100%",
                    maxWidth: "572px",
                    height: "auto",
                  }}
                />
              </Box>
              <Box flex="1" textAlign="right">
                <Box
                  component="img"
                  src={landing_m4_r}
                  alt="Right"
                  sx={{
                    width: "100%",
                    maxWidth: "860px",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
              <Box textAlign="center" width="100%">
                <Box
                  component="img"
                  src={landing_bottom}
                  alt="Center"
                  sx={{
                    width: "100%",
                    maxWidth: "797px",
                    height: "auto",
                    mx: "auto",
                  }}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={landing_trynow}
                    alt="Try Now Button"
                    sx={{
                      mt: 2,
                      cursor: "pointer",
                      width: "200px",
                      height: "auto",
                    }}
                    onClick={handleTry}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          bottom={0}
          minHeight="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="ul"
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  listStyle: "none",
                })}
              >
                <Box component="li" pr={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={20}>
                      关于我们
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" px={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={20}>
                      法律声明及隐私政策
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" px={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={20}>
                      联系我们
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" pl={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={20}>
                      加入星临科技
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <LandingDialog open={infoDialog} handleClose={handleCloseInfoDialog} />
    </PageLayout>
  );
}

export default LandingPage;
