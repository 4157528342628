/**

*/

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Divider,
  IconButton,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import humanIc from "assets/images/user_logo.png";
import { useMaterialUIController, setUser, setApiKeyChange } from "context";
import ConfirmDialog from "examples/ConfirmDialog";
import CreateApiKeyDialog from "examples/APIDialog/CreateAPIDialog";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";

function AccCenter() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const containerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };
  const [successOpen, setSuccessOpen] = useState(false);
  const handleOpenSuccess = () => {
    setSuccessOpen(true);
  };
  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };
  const [failOpen, setFailOpen] = useState(false);
  const handleOpenFail = () => {
    setFailOpen(true);
  };
  const handleCloseFail = () => {
    setFailOpen(false);
  };

  const handleEdit = async () => {
    setIsEditing(false);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/user/${user.id}`,
        {
          name: editedName,
          userid: user.userid,
          balance: user.balance,
          free_balance: user.free_balance,
          free_got: user.free_got,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
        }
      );
      if (response.status === 200) {
        setUser(dispatch, response.data);
        handleOpenSuccess();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenFail();
      }
    } catch (error) {
      console.log(error);
      handleOpenFail();
    }
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [data, setData] = useState([]);
  const loadData = async () => {
    let apps = {};
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll?page=1&limit=9999999&sort=created_time&order=asc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        let appsGot = response.data.result;
        appsGot.forEach((app) => {
          if (app.userid === user.id) {
            apps[app.id] = app.name;
          }
        });
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
        return;
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
      return;
    }
    try {
      let appid = Object.keys(apps);
      let intid = appid.map(Number);
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/apiKey/findByUser`;
      let response = await axios.post(
        url,
        {
          appids: intid,
          page: 1,
          limit: 9999999,
          sort: "created_time",
          order: "asc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
        }
      );
      if (response.status === 200) {
        let keys = response.data.result;
        let data = [];
        keys.forEach((key) => {
          let d = new Date(key.updated_time);
          let timeString = d.toLocaleString();
          data.push({
            id: key.id,
            api: key.api_key,
            showApi: false,
            app_id: key.app_id,
            app_name: apps[key.app_id],
            info: key.description,
            time: timeString,
            name: key.name,
            status: key.status,
          });
        });
        setData(data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    }
  };

  const showApi = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        showApi: !newData[index].showApi,
      };
      return newData;
    });
  };

  const encryptString = (s) => {
    const start = s.slice(0, 4);
    const end = s.slice(-4);
    const maskedString = start + "*".repeat(s.length - 8) + end;
    return maskedString;
  };

  const [create, setCreate] = useState(false);
  const createApi = (apiName, apiInfo) => {
    loadData();
    setCreate(false);
    setApiKeyChange(dispatch, true);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const deleteApi = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/apiKey/${data[selectedApi].id}`, {
        headers: {
          Authorization: `${authData.token}`,
        },
      });
      if (response.status === 200) {
        handleOpenModal();
        loadData();
        setApiKeyChange(dispatch, true);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      setConfirm(false);
    }
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userTime, setUserTime] = useState("");

  useEffect(() => {
    if (user) {
      setEditedName(user.name);
      setUserName(user.name);
      let phone = user.userid;
      phone = phone.slice(0, 3) + " " + phone.slice(3);
      phone = phone.slice(0, 7) + "****" + phone.slice(11);
      setUserPhone(phone);
      let d = new Date(user.created_time);
      let timeString = d.toLocaleString();
      setUserTime(timeString);
      loadData();
    }
  }, [user]);

  useEffect(() => {
    if (apiKeyChange) {
      loadData();
      setApiKeyChange(dispatch, false);
    }
  }, [apiKeyChange]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }

    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleEdit();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, editedName]);

  return (
    <DashboardLayout>
      <Box
        sx={{
          width: "100%",
          height: "70vh",
          marginTop: 4,
          display: "flex",
          gap: 4,
          mx: 4,
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: "0 0 25%",
            height: "100%",
            borderRadius: 2,
            border: "1px solid #ddd",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF 25%)",
          }}
        >
          <Typography variant="h6" mb={2}>
            账号信息
          </Typography>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={humanIc} alt="User Icon" style={{ width: 100, height: 100 }} />
            <Box display="flex" alignItems="center" mb={2} mt={2} ref={containerRef}>
              {isEditing ? (
                <TextField
                  label="用户名"
                  variant="outlined"
                  size="small"
                  value={editedName}
                  onChange={handleNameChange}
                  autoFocus
                />
              ) : (
                <>
                  <Typography variant="body1" component="span" color="#000000">
                    {userName}
                  </Typography>
                  <IconButton onClick={handleEditClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">注册手机</Typography>
              <Typography variant="body2">{userPhone}</Typography>
              <CheckCircleIcon fontSize="small" sx={{ color: "green" }} />
              <Typography variant="body2" color="green">
                已绑定
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box justifyContent="center" alignItems="center" display="flex" mt={1}>
            <Typography variant="body2" color="text.secondary" fontSize={14}>
              注册于{userTime}
            </Typography>
          </Box>
        </Box>

        {/* Right Section */}
        <Box
          sx={{
            flex: "0 0 70%",
            height: "100%",
            borderRadius: 2,
            border: "1px solid #ddd",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF 25%)",
          }}
        >
          <Typography variant="h6" mb={2}>
            我的API-Key
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7f6ce0",
              color: "#ffffff",
              alignSelf: "flex-end",
              mb: 2,
            }}
            onClick={handleOpenCreate}
          >
            创建API KEY
          </Button>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell>API-KEY</TableCell>
                  <TableCell>归属应用</TableCell>
                  <TableCell>描述</TableCell>
                  <TableCell>状态</TableCell>
                  <TableCell>创建时间</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.showApi ? item.api : encryptString(item.api)}</TableCell>
                    <TableCell>{item.app_name}</TableCell>
                    <TableCell>{item.info}</TableCell>
                    <TableCell>{item.status === "AVAILABLE" ? "正常" : "停用"}</TableCell>
                    <TableCell>{item.time}</TableCell>
                    <TableCell>
                      <Typography
                        component="span"
                        sx={{
                          color: "#7f6ce0",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#808080",
                          },
                        }}
                        onClick={() => showApi(index)}
                      >
                        {item.showApi ? "隐藏" : "查看"}
                      </Typography>
                      {" | "}
                      <Typography
                        component="span"
                        sx={{
                          color: "#7f6ce0",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#808080",
                          },
                        }}
                        onClick={() => handleOpenConfirm(index)}
                      >
                        删除
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除API-Key"
        info="是否确认删除当前选择的API-Key? API-Key被删除后无法恢复"
        confirm={deleteApi}
      />
      <CreateApiKeyDialog
        open={create}
        onClose={handleCloseCreate}
        onSubmit={createApi}
        appChange={false}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`已经成功删除选择的Api-Key`}
        time={1000}
      />
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={`我的Api-Keys加载出错，请重试`}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={`删除Api-Key失败，请重试`}
        time={1000}
      />
      <MessageModal
        open={successOpen}
        onClose={handleCloseSuccess}
        message={`更新用户名成功`}
        time={1000}
      />
      <ErrorModal
        open={failOpen}
        onClose={handleCloseFail}
        message={`用户名更新失败，请重试`}
        time={1000}
      />
    </DashboardLayout>
  );
}

export default AccCenter;
