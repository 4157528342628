import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RechargeDialog = ({ open, user, handleClose, handleBack }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const closeDialog = () => {
    setAmount("");
    handleClose();
  };

  const goBack = () => {
    setAmount("");
    handleBack();
  };

  const handleCharge = async () => {
    if (!localStorage.getItem("maasAuthData")) {
      navigate("/authentication/sign-in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.get(`${apiUrl}/task/findAll`, {
        headers: {
          Authorization: `${authData.token}`,
        },
      });
      if (response.status === 200) {
        console.log("Success");
        console.log(response);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        console.log("Failed");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      handleOpenFail();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {/* <IconButton
          aria-label="back"
          onClick={goBack}
          sx={{ position: "absolute", left: 8, top: 8 }}
        >
          <ArrowBackIcon />
        </IconButton> */}
        {/* <Typography ml={5}>个人中心 - 充值</Typography> */}
        <Typography ml={1}>充值账户</Typography>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" fontSize="18px" color="#000000" mb={3} mx={1}>
          您正在体验星觉beta内测版本。如需充值帐户，请联系专员完成充值：
        </Typography>
        <Typography variant="body1" fontSize="18px" color="#000000" mb={3} ml={1}>
          微信：gorillav
        </Typography>
        {/* <Typography variant="body1" mb={2}>
          请输入充值金额：
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={amount}
          onChange={handleAmountChange}
          InputProps={{
            startAdornment: <Typography sx={{ mr: 1 }}>¥</Typography>,
          }}
          placeholder="0.00"
        /> */}
      </DialogContent>

      {/* <DialogActions>
        <Button
          variant="contained"
          onClick={handleCharge}
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          充值
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

RechargeDialog.defaultProps = {
  open: false,
  user: {},
  handleClose: () => {},
  handleBack: () => {},
};

RechargeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default RechargeDialog;
