/**

*/
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ModelsView from "layouts/models_view";
import ModelsTest from "layouts/models_test";
import AppsView from "layouts/apps_view";
import AppsOwn from "layouts/apps_own";
import AppDetail from "layouts/apps_own/app_detail";
import LandingPage from "layouts/landing";
import Dashboard from "layouts/dashboard";

import Icon from "@mui/material/Icon";
import ModelsReview from "layouts/models_review";
import ApiSample from "layouts/apps_own/api_sample";
import NewApi from "layouts/apps_own/new_api";
import MyWallet from "layouts/my_wallet";
import MyOder from "layouts/my_order";
import AccCenter from "layouts/acc_center";

const routes = [
  {
    type: "collapse",
    name: "首页",
    key: "dashboard",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    mustShow: true,
    belong: "console",
  },
  {
    type: "divider",
    name: "dividerConsole1",
    key: "dividerConsole1",
    mustShow: true,
    belong: "console",
  },
  {
    type: "title",
    name: "titleConsole1",
    key: "titleConsole1",
    title: "模型中心",
    mustShow: true,
    belong: "console",
  },
  {
    type: "collapse",
    name: "模型广场",
    key: "models_view",
    icon: <Icon fontSize="small">domain</Icon>,
    route: "/models_view",
    component: <ModelsView />,
    mustShow: true,
    belong: "console",
  },
  {
    type: "collapse",
    name: "模型体验",
    key: "models_test",
    icon: <Icon fontSize="small">forum</Icon>,
    route: "/models_test",
    component: <ModelsTest />,
    mustShow: true,
    belong: "console",
  },
  {
    type: "collapse",
    name: "后台审核",
    key: "models_review",
    icon: <Icon fontSize="small">preview</Icon>,
    route: "/models_review",
    component: <ModelsReview />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "divider",
    name: "dividerConsole2",
    key: "dividerConsole2",
    mustShow: true,
    belong: "console",
  },
  {
    type: "title",
    name: "titleConsole2",
    key: "titleConsole2",
    title: "应用中心",
    mustShow: true,
    belong: "console",
  },
  {
    type: "collapse",
    name: "应用广场",
    key: "apps_view",
    icon: <Icon fontSize="small">apps</Icon>,
    route: "/apps_view",
    component: <AppsView />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "我的应用",
    key: "apps_own",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own",
    component: <AppsOwn />,
    mustShow: true,
    belong: "console",
  },
  {
    type: "collapse",
    name: "登录",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "注册",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "App详情",
    key: "app_detail",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail",
    component: <AppDetail />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "Api调用示例",
    key: "api_sample",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail/api_sample",
    component: <ApiSample />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "新建Api",
    key: "new_api",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail/new_api",
    component: <NewApi />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "collapse",
    name: "欢迎界面",
    key: "landing",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/landing",
    component: <LandingPage />,
    mustShow: false,
    belong: "console",
  },
  {
    type: "button",
    name: "返回控制台",
    key: "buttonpersonal1",
    mustShow: true,
    belong: "personal",
  },
  {
    type: "divider",
    name: "dividerPersonal1",
    key: "dividerPersonal1",
    mustShow: true,
    belong: "personal",
  },
  {
    type: "title",
    name: "titlePersonal1",
    key: "titlePersonal1",
    title: "费用中心",
    mustShow: true,
    belong: "personal",
  },
  {
    type: "collapse",
    name: "我的钱包",
    key: "my_wallet",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/my_wallet",
    component: <MyWallet />,
    mustShow: true,
    belong: "personal",
  },
  {
    type: "collapse",
    name: "我的订单",
    key: "my_order",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/my_order",
    component: <MyOder />,
    mustShow: true,
    belong: "personal",
  },
  {
    type: "divider",
    name: "dividerPersonal2",
    key: "dividerPersonal2",
    mustShow: true,
    belong: "personal",
  },
  {
    type: "title",
    name: "titlePersonal2",
    key: "titlePersonal2",
    title: "个人中心",
    mustShow: true,
    belong: "personal",
  },
  {
    type: "collapse",
    name: "账号管理",
    key: "acc_center",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/acc_center",
    component: <AccCenter />,
    mustShow: true,
    belong: "personal",
  },
];

export default routes;
