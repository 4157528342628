import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";
import axios from "axios";
import { useMaterialUIController, setUser } from "context";

const RewardDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const [screen, setScreen] = useState(1);

  const [successOpen, setSuccessOpen] = useState(false);
  const handleOpenSuccess = () => {
    setSuccessOpen(true);
  };
  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };
  const [failOpen, setFailOpen] = useState(false);
  const handleOpenFail = () => {
    setFailOpen(true);
  };
  const handleCloseFail = () => {
    setFailOpen(false);
  };

  const handleClick = async () => {
    if (user.free_got === false) {
      try {
        if (!localStorage.getItem("maasAuthData")) {
          navigate("/authentication/sign-in");
          return;
        }
        const authData = JSON.parse(localStorage.getItem("maasAuthData"));
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.put(
          `${apiUrl}/user/${user.id}`,
          {
            name: user.name,
            userid: user.userid,
            balance: user.balance,
            free_balance: user.free_balance + 10,
            free_got: true,
          },
          {
            headers: {
              Authorization: `${authData.token}`,
            },
          }
        );
        if (response.status === 200) {
          setUser(dispatch, response.data);
          handleOpenSuccess();
          setScreen(2);
        } else if (response.status === 401) {
          localStorage.clear();
          navigate("/authentication/sign-in");
          return;
        } else {
          handleOpenFail();
        }
      } catch (error) {
        console.log(error);
        handleOpenFail();
      }
    }
  };

  const goPersonal = () => {
    onClose();
    navigate("/my_wallet");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
        }}
      >
        <Typography fontSize={20} color="#737373">
          新用户激励
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", px: 3 }}>
        {screen === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 1,
                fontSize: 46,
              }}
            >
              <CurrencyYenIcon
                sx={{
                  color: "#7f6ce0",
                  mr: 2,
                  borderRadius: "50%",
                  border: "3px solid #ddd",
                  borderColor: "#7f6ce0",
                  p: 0.5,
                }}
              />
              <Typography variant="h3" fontWeight="bold" fontSize={50} color="#000000">
                10.00
              </Typography>
            </Box>
            <Typography variant="body1" color="textSecondary" mb={4}>
              领取新用户奖励金
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: user && user.free_got === false ? "#7f6ce0" : "#737373",
                color: "#fff",
                px: 4,
                py: 1,
                borderRadius: "20px",
                mb: 2,
              }}
              onClick={handleClick}
            >
              {user && user.free_got === false ? "点击领取" : "已领取"}
            </Button>
          </>
        )}
        {screen === 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 1,
                fontSize: 100,
              }}
            >
              <CheckCircleIcon sx={{ color: "#7f6ce0" }} />
            </Box>
            <Typography variant="body1" color="textSecondary" mb={2}>
              领取成功
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#7f6ce0",
                color: "#fff",
                px: 4,
                py: 1,
                borderRadius: "20px",
                mb: 1,
              }}
              onClick={goPersonal}
            >
              前往“个人中心”查看 ↗
            </Button>
          </>
        )}
      </DialogContent>
      <MessageModal
        open={successOpen}
        onClose={handleCloseSuccess}
        message={`免费额度领取成功！`}
        time={1000}
      />
      <ErrorModal
        open={failOpen}
        onClose={handleCloseFail}
        message={`免费额度领取失败！`}
        time={1000}
      />
    </Dialog>
  );
};

RewardDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

RewardDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RewardDialog;
