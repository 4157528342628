import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const LandingDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const closeDialog = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography ml={1}>联系我们</Typography>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontSize="18px" color="#000000" mb={3} mx={1}>
          您正在体验星觉beta内测版本。如需反馈或联系合作，请联系以下合作方式：
        </Typography>
        <Typography variant="body1" fontSize="18px" color="#000000" mb={3} ml={1}>
          微信：gorillav
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

LandingDialog.defaultProps = {
  open: false,
  handleClose: () => {},
};

LandingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LandingDialog;
