/**

*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Fade,
  Backdrop,
  IconButton,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ApiKeyDialog from "examples/APIDialog";
import brandWhite from "assets/images/model_icon.png";
import ConfirmDialog from "examples/ConfirmDialog";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";
import axios from "axios";
import EditApiKeyDialog from "examples/APIDialog/EditAPIDialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateAPIDialogWithFixedApp from "examples/APIDialog/CreateAPIDialogWithFixedApp";
import TokenUsageChart from "examples/TokenUsageChart";
import { useMaterialUIController } from "context";
import { setApiKeyChange } from "context";

function AppDetail() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const [app, setApp] = useState({});
  const [apis, setApis] = useState([]);
  const [token_useds, setTokenUseds] = useState([]);

  const loadApis = async () => {
    let appRef = location.state[0];
    setApp(appRef);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/apiKey/findByUser`;
      let response = await axios.post(
        url,
        {
          appids: [appRef.id],
          page: 1,
          limit: 9999999,
          sort: "created_time",
          order: "asc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
        }
      );
      if (response.status === 200) {
        setApis(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenLoadApiErrorOpen();
      }
    } catch (error) {
      console.log(error);
      handleOpenLoadApiErrorOpen();
    }
  };

  const loadTokens = async () => {
    let appRef = location.state[0];
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/token_used/getAmtByApp?app_id=${encodeURIComponent(appRef.id)}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        setTokenUseds(response.data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        setInfoLabel("获取Token使用记录");
        setInfoText("Token消耗记录获取失败");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("获取Token使用记录");
      setInfoText("Token消耗记录获取失败");
      setError(true);
    }
  };

  const [selectedToken, setSelectedToken] = useState(null);
  const [chartData, setChartData] = useState([]);
  const handleViewMap = async (token) => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/token_used/getAmtMapByAppAndModel?app_id=${app.id}&model_id=${token.model.id}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        setChartData(response.data);
        setSelectedToken(token);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        setInfoLabel("获取Token使用详情");
        setInfoText("Token使用详情获取失败");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("获取Token使用详情");
      setInfoText("Token使用详情获取失败");
      setError(true);
    }
  };
  const handleCloseChart = () => {
    setSelectedToken(null);
  };

  const changeStatus = async (sApi) => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/apiKey/${sApi.id}`,
        {
          name: sApi.name,
          status: sApi.status === "AVAILABLE" ? "UNAVAILABLE" : "AVAILABLE",
          description: sApi.description,
          app_id: sApi.app_id,
          api_key: sApi.api_key,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
        }
      );
      if (response.status === 200) {
        loadApis();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        setInfoLabel("模型状态调整");
        setInfoText("模型状态修改失败");
        setError(true);
        return;
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("模型状态调整");
      setInfoText("模型状态修改失败");
      setError(true);
      return;
    }
  };
  const [editing, setEditing] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const handleSubmitEdit = () => {
    loadApis();
    setOpenEdit(false);
  };
  const handleOpenEdit = (api) => {
    setEditing(api);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [openCreate, setOpenCreate] = useState(false);
  const handleSubmitCreate = () => {
    loadApis();
    setOpenCreate(false);
  };
  const handleOpenCreate = () => {
    setOpenCreate(true);
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state[0]) {
      loadApis();
      loadTokens();
    } else {
      navigate("/apps_own");
    }
  }, [location.state]);

  useEffect(() => {
    if (apiKeyChange) {
      loadApis();
      setApiKeyChange(dispatch, false);
    }
  }, [apiKeyChange]);

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const handleBackApps = () => {
    navigate("/apps_own");
  };
  const handleApiSample = (api) => {
    navigate("/apps_own/app_detail/api_sample", { state: [app, api] });
  };
  const handleNewApi = () => {
    navigate("/apps_own/app_detail/new_api", { state: [app] });
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const deleteApi = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/apiKey/${apis[selectedApi].id}`, {
        headers: {
          Authorization: `${authData.token}`,
        },
      });
      if (response.status === 200) {
        handleOpenModal();
        setConfirm(false);
        loadApis();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        setInfoLabel("模型删除");
        setInfoText("模型删除失败");
        setError(true);
        setConfirm(false);
        return;
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("模型删除");
      setInfoText("模型删除失败");
      setError(true);
      setConfirm(false);
      return;
    }
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [copyOpen, setCopyOpen] = useState(false);
  const handleOpenCopy = () => {
    setCopyOpen(true);
  };
  const handleCloseCopy = () => {
    setCopyOpen(false);
  };

  const [loadApiErrorOpen, setLoadApiErrorOpen] = useState(false);
  const handleOpenLoadApiErrorOpen = () => {
    setLoadApiErrorOpen(true);
  };
  const handleCloseLoadApiErrorOpen = () => {
    setLoadApiErrorOpen(false);
  };

  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  return (
    <DashboardLayout>
      <Box height="95vh" p={1}>
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Box display="flex" alignItems="center" pt="36px">
            <Typography
              pl={4}
              component="h6"
              variant="h4"
              fontWeight="regular"
              color={"#7f6ce0"}
              sx={{ cursor: "pointer" }}
              onClick={handleBackApps}
            >
              我的应用
            </Typography>
            <Typography pl={2} component="h6" variant="h4" fontWeight="regular" color={"#7f6ce0"}>
              / {app.name}
            </Typography>
            <Box flex={1} display="flex" justifyContent="flex-end" pr={4}>
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  mr: 4,
                }}
                src={brandWhite}
              />
            </Box>
          </Box>
          <Typography
            pt={2}
            px={4}
            pb={2}
            component="h6"
            variant="caption"
            fontWeight="regular"
            color={"#000000"}
          >
            应用简介
          </Typography>
          <Typography
            px={4}
            pb="36px"
            component="h6"
            variant="caption"
            fontWeight="light"
            color={"#a0a0a0"}
          >
            {app.description}
          </Typography>
        </Box>
        <Box
          mt={2}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          sx={{
            borderRadius: 1,
            bgcolor: "#ffffff",
            overflow: "visible",
          }}
        >
          <Box width="96%" mx="auto" pt="36px" pb="36px">
            <Typography
              pl={1}
              mb={2}
              component="h6"
              variant="h4"
              fontWeight="regular"
              color={"#000000"}
            >
              Token使用情况
            </Typography>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <TableCell>
                      <Typography fontSize={14}>模型名称</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>该模型总Token消耗</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>过去30天使用Token</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>过去7天使用Token</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>操作</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(token_useds).map(([index, token]) => (
                    <TableRow key={index}>
                      <TableCell>
                        {token.model.show_status !== "LAUNCH"
                          ? token.model.name + "（已下架）"
                          : token.model.name}
                      </TableCell>
                      <TableCell>{token.total}</TableCell>
                      <TableCell>{token.recent30}</TableCell>
                      <TableCell>{token.recent7}</TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#7f6ce0"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                            mr: 2,
                          }}
                          onClick={() => handleViewMap(token)}
                        >
                          查看详情
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedToken && (
              <TokenUsageChart token={selectedToken} data={chartData} onClose={handleCloseChart} />
            )}
          </Box>
        </Box>
        <Box
          mt={2}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          sx={{
            borderRadius: 1,
            bgcolor: "#ffffff",
            overflow: "visible",
          }}
        >
          <Box width="96%" mx="auto" pt="36px" pb="36px">
            <Box display="flex" alignItems="center" mb={2}>
              <Typography pl={1} component="h6" variant="h4" fontWeight="regular" color={"#000000"}>
                API-Key管理
              </Typography>
              <KeyIcon fontSize="small" sx={{ ml: 3 }}>
                KeyIcon
              </KeyIcon>
              <Typography
                pl={1}
                mt={0.5}
                component="h6"
                variant="subtitle2"
                fontWeight="light"
                color={"#7f6ce0"}
                sx={{ cursor: "pointer" }}
                onClick={handleOpenDialog}
              >
                查看我的全部API KEY
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <TableCell>
                      <Typography fontSize={14}>API名称</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>描述</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>API-Key</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>状态</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14}>操作</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apis.map((api, index) => (
                    <TableRow key={index}>
                      <TableCell>{api.name}</TableCell>
                      <TableCell>{api.description}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography
                            component="span"
                            variant="body2"
                            fontWeight="light"
                            color="#000000"
                            mr={1}
                          >
                            {api.api_key}
                          </Typography>
                          <CopyToClipboard text={api.api_key} onCopy={handleOpenCopy}>
                            <ContentCopyIcon
                              color={"#7f6ce0"}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  color: "#808080",
                                },
                              }}
                            />
                          </CopyToClipboard>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 15,
                              height: 15,
                              borderRadius: "50%",
                              bgcolor: api.status === "AVAILABLE" ? "#5aff4b" : "#fd0303",
                              mr: 1,
                            }}
                          />
                          <Typography
                            component="span"
                            variant="body2"
                            fontWeight="light"
                            color="#000000"
                          >
                            {api.status === "AVAILABLE" ? "正常" : "停用"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#7f6ce0"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                            mr: 2,
                          }}
                          onClick={() => handleOpenEdit(api)}
                        >
                          编辑
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#808080"}
                          mr={2}
                        >
                          |
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#7f6ce0"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                            mr: 2,
                          }}
                          onClick={() => handleOpenConfirm(index)}
                        >
                          删除
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#808080"}
                          mr={2}
                        >
                          |
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#7f6ce0"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                          }}
                          onClick={() => changeStatus(api)}
                        >
                          {api.status === "AVAILABLE" ? "禁用" : "启用"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <IconButton onClick={handleOpenCreate}>
                        <AddCircleOutlineIcon />
                        <Typography
                          variant="body2"
                          color="#7f6ce0"
                          sx={{
                            ml: 1,
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          为应用添加新的API-KEY
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除Api"
        info="是否确认删除当前选择的Api? Api删除后无法恢复"
        confirm={deleteApi}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`已经成功删除选择的Api`}
        time={1000}
      />
      <MessageModal
        open={copyOpen}
        onClose={handleCloseCopy}
        message={`已复制该API-Key`}
        time={1000}
      />
      <ErrorModal
        open={loadApiErrorOpen}
        onClose={handleCloseLoadApiErrorOpen}
        message={`API列表载入失败，请重试`}
        time={1000}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <EditApiKeyDialog
        open={openEdit}
        onClose={handleCloseEdit}
        onSubmit={handleSubmitEdit}
        appChange={true}
        prevApi={editing}
      />
      <CreateAPIDialogWithFixedApp
        open={openCreate}
        onClose={handleCloseCreate}
        onSubmit={handleSubmitCreate}
        appChange={true}
        sApp={app}
      />
    </DashboardLayout>
  );
}

export default AppDetail;
