/**

*/
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { Typography, Box } from "@mui/material";

function Footer({ light }) {
  return (
    <Box
      width="100%"
      height="15vh"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
            })}
          >
            <Box component="li" pr={6} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color="#999999" fontSize={18}>
                  关于我们
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={6} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color="#999999" fontSize={18}>
                  法律声明及隐私政策
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={6} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color="#999999" fontSize={18}>
                  联系我们
                </Typography>
              </Link>
            </Box>
            <Box component="li" pl={6} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color="#999999" fontSize={18}>
                  加入星临科技
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

Footer.defaultProps = {
  light: false,
};

Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
