/**

*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography, Box, Button, Popover } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import brand from "assets/images/authpage_logo.png";
import somelogo from "assets/images/authpage_logoset.png";
import axios from "axios";

function BasicLayout({ image, children }) {
  const [models, setModels] = useState([]);
  const getModels = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/model/findByRecommended?recommended=true`;
      let response = await axios.get(url, {});
      if (response.status === 200) {
        setModels(response.data.result);
        console.log(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        alert("获取模型列表出错");
      }
    } catch (error) {
      console.log(error);
      alert("获取模型列表出错");
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{
          background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF)",
        }}
      >
        <Box height="8%" bgcolor="#ffffff" borderBottom={1} borderColor="#7F6CE0">
          <Box display="flex" alignItems="center" height="100%" px={3}>
            <Box
              component="img"
              src={brand}
              alt="Brand"
              sx={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          flex="1"
          justifyContent="center"
          alignItems="center"
          px={2}
          py={4}
        >
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              textAlign: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <Box
              component="img"
              src={somelogo}
              alt="Brand Logo"
              sx={{
                maxWidth: "716px",
                width: "100%",
                height: "auto",
                mb: 5,
              }}
            />
            <Box px={2}>
              <Typography
                variant="h6"
                mb={1}
                color="#999999"
                fontSize={16}
                fontWeight="light"
                sx={{ textAlign: "left" }}
              >
                为您推荐
              </Typography>
              <Grid container spacing={5} justifyContent="center">
                {models.slice(0, 3).map((model, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "12px",
                        textAlign: "center",
                        p: 2,
                        boxShadow: 1,
                        backgroundColor: "#fff",
                        width: "100%",
                        maxWidth: "260px",
                        height: "320px",
                      }}
                    >
                      {/* 图片部分 */}
                      <Box
                        component="img"
                        src={model.logo}
                        alt={model.name}
                        sx={{
                          width: "75px",
                          height: "auto",
                          margin: "0 auto 8px",
                        }}
                      />
                      {/* 标题 */}
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        fontSize="16px"
                        color="#000000"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {model.name}
                      </Typography>
                      {/* 描述 */}
                      <Typography
                        variant="body2"
                        color="#737373"
                        fontSize="14px"
                        mb={2}
                        sx={{
                          wordWrap: "break-word",
                          lineHeight: 1.4,
                          height: "40px",
                          overflow: "hidden",
                        }}
                      >
                        {model.text_summary}
                      </Typography>
                      {/* 输入输出 */}
                      <Box display="flex" justifyContent="space-between" gap={2}>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          输入
                        </Typography>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          输出
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" gap={2}>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          {model.token_price_input * 1000000}
                        </Typography>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          {model.token_price_output * 1000000}
                        </Typography>
                      </Box>
                      {/* Token 部分 */}
                      <Box display="flex" justifyContent="space-between" gap={2} mb={1}>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          {"元/M token"}
                        </Typography>
                        <Typography variant="body2" color="#737373" fontSize="12px">
                          {"元/M token"}
                        </Typography>
                      </Box>
                      {/* 按钮 */}
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: "#7F6CE0",
                          "&:hover": {
                            color: "#4a4a4a",
                            backgroundColor: "transparent",
                          },
                          fontSize: "12px",
                        }}
                        onClick={handleButtonClick}
                      >
                        {"查看模型"}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box
            flex="1"
            sx={{
              width: "20vw",
              height: "auto",
              maxWidth: "20vw",
              borderColor: "#EDEAFA",
              backgroundColor: "white",
              mr: "10%",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <Footer />
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": {
            background: "linear-gradient(135deg, #E3F2FD, #FFF)",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            animation: "fadeIn 0.3s ease",
          },
        }}
      >
        <Box textAlign="center">
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "#333",
            }}
          >
            请先注册登录
          </Typography>
        </Box>
      </Popover>
    </PageLayout>
  );
}

BasicLayout.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
