import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import humanIc from "assets/images/user_logo.png";
import EditIcon from "@mui/icons-material/Edit";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserProfileDialog = ({ open, handleClose, user, handleSubmit, handleOpenCharge }) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(user.name);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const handleOpenSuccess = () => {
    setSuccessOpen(true);
  };
  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };
  const [failOpen, setFailOpen] = useState(false);
  const handleOpenFail = () => {
    setFailOpen(true);
  };
  const handleCloseFail = () => {
    setFailOpen(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedName(user.name);
    handleClose();
  };

  const goCharge = () => {
    setIsEditing(false);
    setEditedName(user.name);
    handleOpenCharge();
  };

  const handleEdit = async () => {
    setIsEditing(false);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/user/${user.id}`,
        {
          name: editedName,
          userid: user.userid,
          balance: user.balance,
          free_balance: user.free_balance,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
        }
      );
      if (response.status === 200) {
        handleSubmit(response.data);
        handleOpenSuccess();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenFail();
      }
    } catch (error) {
      console.log(error);
      handleOpenFail();
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography>个人中心</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            sx={{
              height: 35,
              width: 35,
              mt: 5,
              mx: 7,
            }}
            src={humanIc}
          />
          <Box>
            <Box display="flex" alignItems="center" mb={2} mt={2}>
              {isEditing ? (
                <TextField
                  label="用户名"
                  variant="outlined"
                  size="large"
                  value={editedName}
                  onChange={handleNameChange}
                />
              ) : (
                <>
                  <Typography variant="body1" component="span" color="#000000">
                    用户名: {user.name}
                  </Typography>
                  <IconButton onClick={handleEditClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ cursor: "pointer" }}
                    onClick={handleEditClick}
                  >
                    编辑
                  </Typography>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" component="span" color="#000000">
                账户余额: {user.balance} + {user.free_balance}
              </Typography>
              <IconButton onClick={goCharge}>
                <AccountBalanceWalletIcon fontSize="small" />
              </IconButton>
              <Typography
                variant="body2"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={goCharge}
              >
                充值
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleEdit}
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          更新
        </Button>
      </DialogActions>
      <MessageModal
        open={successOpen}
        onClose={handleCloseSuccess}
        message={`更新用户名成功`}
        time={1000}
      />
      <ErrorModal
        open={failOpen}
        onClose={handleCloseFail}
        message={`用户名更新失败，请重试`}
        time={1000}
      />
    </Dialog>
  );
};

UserProfileDialog.defaultProps = {
  open: false,
  user: {},
  handleClose: () => {},
  handleSubmit: () => {},
  handleOpenCharge: () => {},
};

UserProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleOpenCharge: PropTypes.func.isRequired,
};

export default UserProfileDialog;
