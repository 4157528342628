import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Switch,
} from "@mui/material";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

const EditApiKeyDialog = ({ open, onClose, onSubmit, appChange, prevApi }) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [name, setName] = useState("");
  const [selectedApp, setSelectedApp] = useState({});
  const [description, setDescription] = useState("");
  const [checked, setChecked] = useState(true);

  const handleNameChange = (event) => {
    if (event.target.value.length <= 15) {
      setName(event.target.value);
    }
  };

  const handleAppChange = (event) => {
    const appId = event.target.value;
    const app = apps.find((app) => app.id === appId);
    setSelectedApp(app);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 30) {
      setDescription(event.target.value);
    }
  };

  const handleCheckChange = (event) => {
    setChecked(!checked);
  };

  const [newApiFail, setNewApiFail] = useState(false);
  const handleOpenNewApiFail = () => {
    setNewApiFail(true);
  };
  const handleCloseNewApiFail = () => {
    setNewApiFail(false);
  };
  const handleSubmit = async () => {
    if (selectedApp && description && name) {
      try {
        if (!localStorage.getItem("maasAuthData")) {
          navigate("/authentication/sign-in");
          return;
        }
        const authData = JSON.parse(localStorage.getItem("maasAuthData"));
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.put(
          `${apiUrl}/apiKey/${prevApi.id}`,
          {
            name: name,
            status: checked ? "AVAILABLE" : "UNAVAILABLE",
            description: description,
            app_id: prevApi.app_id,
            api_key: prevApi.api_key,
          },
          {
            headers: {
              Authorization: `${authData.token}`,
            },
          }
        );
        if (response.status === 200) {
          onSubmit();
          setSelectedApp({});
          setDescription("");
          setName("");
          setChecked(true);
        } else if (response.status === 401) {
          localStorage.clear();
          navigate("/authentication/sign-in");
          return;
        } else {
          handleOpenNewApiFail();
        }
      } catch (error) {
        console.log(error);
        handleOpenNewApiFail();
      }
    }
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [apps, setApps] = useState([]);
  const getApps = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll?page=1&limit=9999999&sort=created_time&order=asc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        let apps = response.data.result;
        apps = apps.filter((app, index) => app.userid === user.id);
        setApps(apps);
        if (apps.length > 0) {
          let dApp = apps.find((app) => app.id === prevApi.app_id);
          setSelectedApp(dApp);
        }
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (open && prevApi) {
      getApps();
      setName(prevApi.name);
      setDescription(prevApi.description);
      setChecked(prevApi.status === "AVAILABLE" ? true : false);
    }
  }, [open]);

  if (!open || !prevApi || !user) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>编辑已有的API-KEY</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="app-select-label">归属应用</InputLabel>
          <Select
            labelId="app-select-label"
            value={selectedApp.id || ""}
            onChange={handleAppChange}
            sx={{ height: "3vh" }}
            input={<OutlinedInput label="归属应用" />}
            disabled={appChange}
          >
            {apps.map((app) => (
              <MenuItem key={app.id} value={app.id}>
                {app.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="名称"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          helperText={name ? `${name.length}/15` : "0/15"}
          placeholder="请输入名称(最长15个字)"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <TextField
          label="描述"
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          helperText={description ? `${description.length}/30` : "0/30"}
          placeholder="请输入描述(最长30个字)"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleCheckChange} />}
            label="启用状态"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={`归属应用加载出错，请重试`}
        time={1000}
      />
      <ErrorModal
        open={newApiFail}
        onClose={handleCloseNewApiFail}
        message={`修改Api-Key信息失败，请重试`}
        time={1000}
      />
    </Dialog>
  );
};

EditApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  appChange: false,
  prevApi: {},
};

EditApiKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  appChange: PropTypes.bool.isRequired,
  prevApi: PropTypes.object.isRequired,
};

export default EditApiKeyDialog;
