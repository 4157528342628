/**

*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, IconButton, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CodeBlock from "examples/CodeSample/CodeBlock";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ApiSample() {
  const navigate = useNavigate();
  const [app, setApp] = useState({});
  const [api, setApi] = useState({});
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state[0] && location.state[1]) {
      setApp(location.state[0]);
      setApi(location.state[1]);
    } else {
      navigate("/apps_own");
    }
  }, [location.state]);

  const handleBackApps = () => {
    navigate("/apps_own");
  };
  const handleBackAppDetail = () => {
    navigate("/apps_own/app_detail", { state: [app] });
  };

  const [language, setLanguage] = useState("python");
  let template = `
def fibonacci(n):
  """Return the n-th Fibonacci number."""
  if n <= 0:
    return 0
  elif n == 1:
    return 1
  else:
    return fibonacci(n-1) + fibonacci(n-2)

def print_fibonacci_sequence(count):
  """Print a Fibonacci sequence of a given count."""
  sequence = [fibonacci(i) for i in range(count)]
  print(f"The first {count} numbers in the Fibonacci sequence are: {sequence}")

if __name__ == "__main__":
  print_fibonacci_sequence(10)
  `;
  const [codeContent, setCodeContent] = useState(template);

  return (
    <DashboardLayout>
      <Box height="95vh">
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          height="100%"
          sx={{
            borderRadius: 2,
            bgcolor: "#ffffff",
            overflow: "auto",
            padding: 4,
          }}
        >
          <Box mb={2} display="flex" alignItems="center">
            <Typography
              pl={1}
              component="h6"
              variant="h4"
              fontWeight="regular"
              color={"#7f6ce0"}
              sx={{ cursor: "pointer" }}
              onClick={handleBackApps}
            >
              我的应用
            </Typography>
            <Typography
              pl={2}
              component="h6"
              variant="h4"
              fontWeight="regular"
              color={"#7f6ce0"}
              sx={{ cursor: "pointer" }}
              onClick={handleBackAppDetail}
            >
              / {app.name}
            </Typography>
            <Typography pl={2} component="h6" variant="h4" fontWeight="regular" color={"#7f6ce0"}>
              / {api.name}
            </Typography>
          </Box>
          <Box mb={2} display="flex" alignItems="center">
            <IconButton onClick={handleBackAppDetail}>
              <ArrowBackIcon sx={{ color: "#7f6ce0" }} />
            </IconButton>
            <Typography variant="h4" fontWeight="regular" sx={{ ml: 1 }}>
              调用方式
            </Typography>
          </Box>
          <Box mb={2}>
            <Button
              variant="outlined"
              sx={{
                color: "#7f6ce0",
                borderColor: "#7f6ce0",
                bgcolor: "#d0c5da",
                "&:hover": {
                  color: "#808080",
                },
                fontSize: 15,
                fontWeight: "regular",
                ml: 1,
              }}
            >
              {language}
            </Button>
          </Box>
          <Box
            sx={{
              ml: 1,
              width: "99%",
              backgroundColor: "#2d2d2d",
              color: "#ffffff",
              borderRadius: "8px",
              paddingTop: "30px",
              fontFamily: "monospace",
              fontSize: "0.875rem",
            }}
          >
            <CodeBlock language={language} code={codeContent} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default ApiSample;
